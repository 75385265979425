import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import Hours from 'react-hours'
import Multiselect from 'multiselect-react-dropdown';
import OrderDeviceCTA from './components/OrderDeviceCTA'


const fetchDevices = () => {
  return new Promise(resolve => {
    axios.get('restaurants/<restaurant_id>/devices')
      .then(response => {
        const data = response.data
        data.sort((a, b) => a.device_id > b.device_id ? 1 : -1)
        resolve(data)
      })
  })
}

const fetchLayouts = () => {
  return new Promise(resolve => {
    axios.get('restaurants/<restaurant_id>/layouts')
      .then(response => {
        const data = response.data
        data.sort((a, b) => a.layout_id > b.layout_id ? 1 : -1)
        resolve(data)
      })
  })
}

const fetchSchedule = (deviceId) => {
  return new Promise(resolve => {
    axios.get(`devices/${deviceId}/schedule`)
      .then(response => {
        resolve(response.data)
      })
  })
}

const DeviceSelect = ({ devices, onChangeValue, defaultValue, label }) => {
  return <div className="device-select">
    <label>
      {label ?? "Skärm:"}
      <select
        onChange={e => onChangeValue(e.target.value)}
        defaultValue={defaultValue}
      >
        {devices.map(({ name, device_id }) =>
          <option key={device_id} value={device_id}>{name}</option>
        )}
      </select>
    </label>
  </div>
}


const DeviceScheduleEditor = ({ deviceId, layouts }) => {
  const getAdditionCountRange = () => {
    if (!schedule) {
      return []
    }

    const additionCount = Math.max(1, schedule.length)
    const additionCountRange = [...Array(additionCount).keys()]
    return additionCountRange
  }

  const [schedule, setSchedule] = useState()
  // const [lunchTimeStart, setLunchTimeStart] = useState()
  // const [lunchTimeEnd, setLunchTimeEnd] = useState()
  // const [dinnerLayoutId, setDinnerLayoutId] = useState()
  // const [lunchLayoutId, setLunchLayoutId] = useState()
  // const [hasLoaded, setHasLoaded] = useState()
  // const [schedule, setOpeningHoursList] = useState([])
  const [additionCountRange, setAdditionCountRange] = useState(getAdditionCountRange())

  useEffect(() => {
    setAdditionCountRange(getAdditionCountRange)
  }, [schedule])

  useEffect(() => {
    console.log("fetching schedule for device", deviceId)

    fetchSchedule(deviceId)
      .then((schedule) => {
        // console.log("schedule loaded", schedule)
        schedule.sort((a, b) => a.priority > b.priority ? 1 : -1)

        let isValid = true

        if (schedule.length === 0) {
          isValid = false
        } else if (schedule.length > 0) {
          const lowestPriority = Math.min(...schedule.map(s => s.priority))

          if (lowestPriority !== 1) {
            isValid = false
          } else {
            const lowestPriorityRow = schedule.find(s => s.priority === lowestPriority)

            if (lowestPriorityRow.opening_hours !== "24/7") {
              isValid = false
            }
          }
        }

        if (!isValid) {
          console.log("invalid schedule, resetting", schedule)
          setSchedule([{ opening_hours: '24/7', priority: 1, layout_id: layouts[0].layout_id }])
        } else {
          console.log("schedule is valid", schedule)
          setSchedule(schedule)
        }

        // setHasLoaded(true)
      })
  }, [deviceId])


  // useEffect(() => {
  //   if (!schedule) {
  //     return
  //   }

  //   // console.log(layouts)

  // }, [schedule])

  const save = () => {
    // if (!lunchLayoutId || !dinnerLayoutId) {
    //   console.log("lunch or dinner layout id not set")
    //   return
    // }

    // const data = [
    //   {
    //     "opening_hours": `Mo-Fr ${lunchTimeStart}-${lunchTimeEnd}`,
    //     "layout_id": lunchLayoutId,
    //     "priority": 2
    //   },
    //   {
    //     "opening_hours": "24/7",
    //     "layout_id": dinnerLayoutId,
    //     "priority": 1
    //   }
    // ]

    const data = schedule

    console.log("saving", data)

    axios
      .put(`devices/${deviceId}/schedule`, data)
      .then(response => {
        if (response.status !== 200) {
          console.error(response)
        }
      })
  }

  // Visa [Layout 1]
  // men [varje dag] [mellan kl [08:30] - [14:00]], visa [Layout 2]
  // men [lör, sön] [dygnet runt] visa [Layout 3]

  if (schedule === undefined) {
    return "Laddar..."
  }

  const onChangeSelectedLayoutId = (index, selectedLayoutId) => {
    console.log("onChangeSelectedLayoutId", index + 1, selectedLayoutId)

    const exists = schedule[index + 1] !== undefined

    if (exists) {
      setSchedule(schedule => {
        schedule[index + 1].layout_id = selectedLayoutId
        return [...schedule]
      })
    }
  }

  const onChangeOpeningHours = (index, openingHours) => {
    console.log("onChangeOpeningHours", index + 1, openingHours)

    const exists = schedule[index + 1] !== undefined
    // const exists = index + 1 < schedule.length

    if (openingHours === null) {
      // remove index if exists
      if (exists) {
        console.log("removing schedule row", schedule[index + 1])
        setSchedule(schedule => {
          schedule.splice(index + 1, 1)
          return [...schedule]
        })
      }
    } else {
      if (exists) {
        console.log("updating")
        setSchedule(schedule => {
          schedule[index + 1].opening_hours = openingHours
          return [...schedule]
        })
      } else {
        console.log("creating new schedule row")
        setSchedule(schedule => {
          schedule.push({
            opening_hours: openingHours,
            layout_id: layouts[0].layout_id,
            priority: index + 2
          })
          return [...schedule]
        })
      }
    }
  }

  return <div>
    <label>
      Visa
      <select 
        onChange={e => {
          setSchedule(schedule => {
            schedule[0].layout_id = e.target.value
            return [...schedule]
          })
        }} 
        defaultValue={schedule[0].layout_id}
        >
        {layouts.map(({ name, layout_id }) =>
          <option key={layout_id} value={layout_id}>{name}</option>
        )}
      </select>
    </label>
    {additionCountRange.map(i => {
      const rowExists = i < schedule.length - 1

      if (rowExists) {
        const scheduleRow = schedule[i + 1]
        return <ScheduleAddition
          key={i}
          index={i}
          layouts={layouts}
          openingHours={scheduleRow.opening_hours}
          selectedLayoutId={scheduleRow.layout_id}
          onChangeOpeningHours={onChangeOpeningHours}
          onChangeSelectedLayoutId={onChangeSelectedLayoutId}
        />
      } else {
        return <ScheduleAddition
          key={i}
          index={i}
          layouts={layouts}
          onChangeOpeningHours={onChangeOpeningHours}
          selectedLayoutId={layouts[0].layout_id}
        />
      }
    })}
    <br />
    <button onClick={save}>Spara</button>
  </div>
}

const ScheduleAddition = ({
  layouts,
  openingHours,
  onChangeOpeningHours,
  selectedLayoutId,
  onChangeSelectedLayoutId,
  index
}) => {
  const [selectedDays, setSelectedDays] = useState([])
  const [timeMode, setTimeMode] = useState("1")
  const [fromTime, setFromTime] = useState()
  const [toTime, setToTime] = useState()
  const [hasLoaded, setHasLoaded] = useState(false)

  const days = [
    { name: 'Måndag', id: 1 },
    { name: 'Tisdag', id: 2 },
    { name: 'Onsdag', id: 3 },
    { name: 'Torsdag', id: 4 },
    { name: 'Fredag', id: 5 },
    { name: 'Lördag', id: 6 },
    { name: 'Söndag', id: 7 },
  ]

  useEffect(() => {
    if (!openingHours) {
      setHasLoaded(true)
      return
    }

    console.log("handling", openingHours)

    const newSelectedDays = []
    if (openingHours.includes("Mo")) {
      newSelectedDays.push(days[0])
    }
    if (openingHours.includes("Tu")) {
      newSelectedDays.push(days[1])
    }
    if (openingHours.includes("We")) {
      newSelectedDays.push(days[2])
    }
    if (openingHours.includes("Th")) {
      newSelectedDays.push(days[3])
    }
    if (openingHours.includes("Fr")) {
      newSelectedDays.push(days[4])
    }
    if (openingHours.includes("Sa")) {
      newSelectedDays.push(days[5])
    }
    if (openingHours.includes("Su")) {
      newSelectedDays.push(days[6])
    }
    setSelectedDays(newSelectedDays)

    if (openingHours.includes(":")) {
      setTimeMode("2")

      const timeRangeString = openingHours.split(" ")[1]
      const [from, to] = timeRangeString.split("-")
      setFromTime(from)
      setToTime(to)
    } else {
      setTimeMode("1")
    }

    setHasLoaded(true)
  }, [])


  const getPlaceholder = () => {
    if (selectedDays.length === 7) {
      return "varje dag"
    }

    if (selectedDays.length === 0) {
      return "..."
    }

    const values = []

    if (selectedDays.findIndex(x => x.id === 1) !== -1) {
      values.push("Må")
    }
    if (selectedDays.findIndex(x => x.id === 2) !== -1) {
      values.push("Ti")
    }
    if (selectedDays.findIndex(x => x.id === 3) !== -1) {
      values.push("On")
    }
    if (selectedDays.findIndex(x => x.id === 4) !== -1) {
      values.push("To")
    }
    if (selectedDays.findIndex(x => x.id === 5) !== -1) {
      values.push("Fr")
    }
    if (selectedDays.findIndex(x => x.id === 6) !== -1) {
      values.push("Lö")
    }
    if (selectedDays.findIndex(x => x.id === 7) !== -1) {
      values.push("Sö")
    }

    return values.join(",")
  }

  const onSelectDay = (e) => {
    setSelectedDays(e)
  }

  useEffect(() => {
    if (!hasLoaded) {
      return
    }

    let isValid = true
    let openingHours = ""

    if (selectedDays.length === 0) {
      isValid = false
    } else {
      const dayList = []
      if (selectedDays.findIndex(x => x.id === 1) !== -1) {
        dayList.push("Mo")
      }
      if (selectedDays.findIndex(x => x.id === 2) !== -1) {
        dayList.push("Tu")
      }
      if (selectedDays.findIndex(x => x.id === 3) !== -1) {
        dayList.push("We")
      }
      if (selectedDays.findIndex(x => x.id === 4) !== -1) {
        dayList.push("Th")
      }
      if (selectedDays.findIndex(x => x.id === 5) !== -1) {
        dayList.push("Fr")
      }
      if (selectedDays.findIndex(x => x.id === 6) !== -1) {
        dayList.push("Sa")
      }
      if (selectedDays.findIndex(x => x.id === 7) !== -1) {
        dayList.push("Su")
      }
      openingHours += dayList.join(",")
    }

    if (timeMode === "2") {
      if (fromTime && fromTime.length === 5 && toTime && toTime.length === 5) {
        openingHours += " " + fromTime + "-" + toTime
      } else {
        isValid = false
      }
    }

    if (isValid) {
      // console.log("valid opening hours, commiting")
      onChangeOpeningHours(index, openingHours)
    } else {
      // console.log("invalid opening hours")
      onChangeOpeningHours(index, null)
    }

  }, [selectedDays, fromTime, toTime])

  return <div className="schedule-addition">
    <span>men</span>
    <Multiselect
      options={days}
      selectedValues={selectedDays}
      onSelect={onSelectDay}
      onRemove={onSelectDay}
      displayValue="name"
      showCheckbox={true}
      closeOnSelect={false}
      avoidHighlightFirstOption={true}
      placeholder={getPlaceholder()}
      style={{
        chips: { display: 'none' },
        searchBox: { padding: '0', maxWidth: '120px' },
        option: { padding: '2px' }
      }}
    />
    {(selectedDays.length > 0) && <>
      <select onChange={(e) => setTimeMode(e.target.value)} defaultValue={timeMode}>
        <option value={"1"}>dygnet runt</option>
        <option value={"2"}>mellan kl</option>
      </select>
      {(timeMode === "2") && <>
        <input
          type="time"
          defaultValue={fromTime}
          onBlur={(e) => setFromTime(e.target.value)}
        />
        <span>och</span>
        <input
          type="time"
          defaultValue={toTime}
          onBlur={(e) => setToTime(e.target.value)}
        />
      </>}

      <span> visa</span>
      <select onChange={e => onChangeSelectedLayoutId(index, e.target.value)} defaultValue={selectedLayoutId}>
        {layouts.map(({ name, layout_id }) =>
          <option key={layout_id} value={layout_id}>{name}</option>
        )}
      </select>

    </>}
  </div>
}

const ScheduleEditor = () => {
  const [devices, setDevices] = useState()
  const [currentDeviceId, setCurrentDeviceId] = useState()
  const [layouts, setLayouts] = useState()

  const currentDevice = devices && devices.find(x => x.device_id === currentDeviceId)

  useEffect(() => {
    if (!devices) {
      fetchDevices()
        .then(data => {
          setDevices(data)
          if(data.length > 0) {
            setCurrentDeviceId(data[0].device_id)
          }
        })
    }
    if (!layouts) {
      fetchLayouts()
        .then(setLayouts)
    }
  }, [])

  if (!layouts || !devices) {
    return "Laddar..."
  }

  // return <div>
  //   {devices && <DeviceSelect
  //     devices={devices}
  //     onChangeValue={setCurrentDeviceId}
  //     defaultValue={currentDeviceId}
  //   />}
  //   {currentDevice && <DeviceScheduleEditor
  //     key={currentDeviceId}
  //     deviceId={currentDeviceId}
  //     device={currentDevice}
  //     layouts={layouts}
  //   />}
  // </div>

  return <div className="schedule-editor">
    {(devices && devices.length === 0) && <OrderDeviceCTA />}
    {(devices && layouts.length > 0) && devices.map(device =>
      <div className="schedule-editor-device" key={device.device_id}>
        <b>{device.name}</b>
        <DeviceScheduleEditor
          deviceId={device.device_id}
          device={device}
          layouts={layouts}
        />
      </div>
    )}
  </div>
}

export default ScheduleEditor
