import { getPropertyStatus } from '../utils'
import { useRef } from 'react'
import TextValueEdit from './TextValueEdit'
import Item from './Item'

const SectionTitle = props => {
  const { baseValue, newValue, onSave } = props
  const status = getPropertyStatus(props)
  const inputEl = useRef(null)

  return (
    <div
      className={`section-title section-title-status--${status}`}
      onClick={() => {
        inputEl.current.focus()
      }}
    >
      <span className={'text--value text--base-value'}>{baseValue}</span>
      <TextValueEdit
        className={'value-text-edit'}
        previousValue={newValue ?? baseValue}
        onSave={onSave}
        newValue={newValue}
        inputRef={inputEl}
        placeholder={'title'}
      />
    </div>
  )
}

function getSectionStatus(section) {
  if (section._removed_) {
    return 'removed'
  } else if (section._new_) {
    return 'new'
  } else {
    return 'none'
  }
}

const Section = ({
  sectionId,
  section,
  removeSection,
  setSectionTitle,
  addPatch,
  removePatch,
  addNewDish
}) => {
  const sortItems = ([aId, a], [bId, b]) => {
    const aIndex = a._new_index ?? a.index ?? 0
    const bIndex = b._new_index ?? b.index ?? 0
    return aIndex - bIndex
  }

  return (
    <div
      className={`section section-status--${getSectionStatus(section)}`}
    >
      <div className={'section-head'}>
        <div
          className={'remove-section-button'}
          onClick={() => {
            removeSection(sectionId, section)
          }}
        >
          ❌
        </div>

        <SectionTitle
          baseValue={section.title}
          newValue={section._new_title}
          onSave={value => {
            setSectionTitle(sectionId, value)
          }}
        />
      </div>

      <div className={'section-dishes'}>
        {Object.entries(section.dishes)
          .filter(([key, value]) => key !== '_new_')
          .sort(sortItems)
          .map(([dish_id, item]) => (
            <Item
              sectionIndex={sectionId}
              itemIndex={dish_id}
              item={item}
              key={dish_id}
              addPatch={addPatch}
              removePatch={removePatch}
              id={dish_id}
            />
          ))}
      </div>
      <button
        className={'new-dish-button'}
        onClick={() => addNewDish(sectionId)}
      >
        Ny rätt
      </button>
    </div>
  )
}

export default Section
