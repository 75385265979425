import { Logo } from './Logo'
import { EmailInput, PasswordInput, FormButton } from './Form'
import styled from 'styled-components'

const ErrorBox = styled.div`
  color: red;
  width: 100%;
  height: 48px;
  border: 1px solid red;
  box-sizing: border-box;
  margin: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 14px;
  background: white;
  box-shadow: 0px 0px 10px 2px rgb(0 0 0 / 10%);
  opacity: 1;
  transition: all 1s;
  border-radius: 6px;
`

export const LoginForm = ({ onSubmit, errorMessage }) => {
  return (<div className={'app-login'}>
    <div className='login-bg-dim'>
      <div className='login-box'>
        <Logo />
        <form onSubmit={onSubmit} action='javascript:void(0);'>
          <EmailInput placeholder='du@exempel.nu' autoCapitalize="none" type="email" />
          <PasswordInput placeholder='**********' type="password" />
          <FormButton>Logga in</FormButton>
          {errorMessage && <ErrorBox>
            {errorMessage}
          </ErrorBox>}
        </form>
      </div>
    </div>
  </div>)
}
