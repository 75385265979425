import { useState, useEffect, useCallback } from 'react'

const TextValueEdit = ({
  previousValue,
  newValue,
  className,
  onSave,
  inputRef,
  placeholder
}) => {
  const [state, setState] = useState({ text: newValue ?? '' })

  useEffect(() => {
    if (newValue) {
      setState({ text: newValue })
    }
  }, [newValue])

  const blur = () => document.activeElement.blur()

  const updateHeight = useCallback(() => {
    const el = inputRef.current
    el.style.height = '5px'
    el.style.height = el.scrollHeight + 'px'
  }, [inputRef])

  useEffect(() => {
    updateHeight()

    if (state.foo) {
      state.foo()
      setState({ text: state.text })
    }
  }, [state, updateHeight])

  const save = () => {
    if (state.text !== previousValue) {
      onSave(state.text)
    }
  }

  const onKeyDown = e => {
    if (e.keyCode === 13 /* ENTER */) {
      e.preventDefault()
      save()
      setState({ ...state, foo: blur })
    } else if (e.keyCode === 27 /* ESC */) {
      if (newValue) {
        setState({ text: previousValue, foo: blur })
      } else {
        setState({ text: '', foo: blur })
      }

      e.preventDefault()
    } else if (e.keyCode === 9 /* TAB */) {
    } else {
      updateHeight()
    }
  }

  return (
    <textarea
      onFocus={e => updateHeight()}
      onKeyDown={onKeyDown}
      rows='1'
      className={className}
      value={state.text}
      onChange={event => {
        setState({ text: event.target.value })
      }}
      onBlur={() => {
        setState({ ...state, foo: save })
      }}
      ref={inputRef}
      placeholder={placeholder}
    />
  )
}

export default TextValueEdit
