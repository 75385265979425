import './App.css'
import { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import { DndContext, useSensor, useSensors, MouseSensor } from '@dnd-kit/core'
import { loadEditMenu, newEmptyPucket, newStartingPucket } from './utils'
import Section from './components/Section'
import fileDownload from 'js-file-download'
import { useStore } from './store'

const MenuEditor = ({ token }) => {
  const restaurantId = window.restaurantId
  const headers = () => ({ Authorization: 'Bearer ' + token })
  const [basePucket, setBasePucket] = useState(newEmptyPucket())
  const [basePucketId, setBasePucketId] = useState('')

  const [state, setState] = useState(() => {
    const newPucket = newEmptyPucket()
    const menu = loadEditMenu(basePucket, newPucket)

    const initialState = { pucket: newPucket, menu: menu }
    return initialState
  })

  const [puckets, setPuckets] = useState()
  const [currentPucketId, setCurrentPucketId] = useState()
  const unsaved = useStore(state => state.unsaved)
  const setUnsaved = useStore(state => state.setUnsaved)

  useEffect(() => {
    axios
      .get('restaurants/<restaurant_id>/puckets', {
        headers: headers()
      })
      .then(response => {
        console.log('puckets', response.data)
        setPuckets(response.data)
        if (response.data.length > 0) {
          setCurrentPucketId(response.data[0].id)
        }
      })
  }, [token])

  useEffect(() => {
    if (!currentPucketId) {
      return
    }

    axios
      .get('restaurants/<restaurant_id>/puckets/' + currentPucketId, {
        headers: headers()
      })
      .then(response => {
        const pucket = response.data

        if (pucket.based_on.length > 0) {
          const basePucketId = pucket.based_on[0]

          axios
            .get('restaurants/<restaurant_id>/puckets/' + basePucketId, {
              headers: headers()
            })
            .then(response => {
              const newBasePucket = response.data

              const newMenu = loadEditMenu(newBasePucket, pucket)
              setBasePucket(newBasePucket)
              setState(state => ({
                ...state,
                menu: newMenu
              }))
            })
        }

        const newMenu = loadEditMenu(basePucket, pucket)

        setState(state => ({
          ...state,
          pucket: pucket,
          menu: newMenu,
          restaurantId: restaurantId,
          pucketId: currentPucketId
        }))
      })
      .catch(e => {
        console.error(e)
        // console.log("pucket doesn't exist on server yet")
      })
  }, [token, currentPucketId, basePucketId])

  const mouseSensor = useSensor(MouseSensor)
  const sensors = useSensors(mouseSensor)

  const commitPucket = (restaurantId, pucketId, pucket) => {
    console.log("base pucket", basePucketId, basePucket)
    console.log("committing", restaurantId, pucketId, pucket)

    axios
      .put(
        `restaurants/<restaurant_id>/puckets/${pucketId}`,
        pucket,
        { headers: { Authorization: 'Bearer ' + token } }
      )
      .then(response => {
        if (response.status === 200) {
          setUnsaved(false)
        } else {
          console.error(response)
        }
      })
      .catch(exception => {
        // console.log(Object.keys(exception))
        console.error(exception.response.data.detail)
      })
  }

  const deletePucket = () => {
    console.log("Deleting pucket!", token)

    axios
      .delete(
        `restaurants/<restaurant_id>/puckets/${state.pucketId}`,
        { headers: { Authorization: 'Bearer ' + token } }
      )
      .then(response => {
        if (response.status !== 200) {
          console.error(response)
        } else {
          console.log("Pucket successfully deleted.")
          window.location.reload()
        }
      })
      .catch(exception => {
        // console.log(Object.keys(exception))
        console.error(exception.response.data.detail)
      })
  }

  const addPatch = newPatch => {
    const newPucket = state.pucket
    newPucket.patches.push(newPatch)
    const newMenu = loadEditMenu(basePucket, newPucket)

    // commitPucket(state.restaurantId, state.pucketId, newPucket)

    setState({ ...state, pucket: { ...newPucket }, menu: { ...newMenu } })
    setUnsaved(true)
  }

  const removePatch = likePatch => {
    const newPucket = state.pucket
    newPucket.patches = newPucket.patches.filter(patch => {
      return !(patch.op === likePatch.op && patch.path === likePatch.path)
    })

    // when removing a new (added) dish, remove all patches refering to it
    // trying this: should be able to remove all patches with path that starts with likePatch.path (should mean they are children)
    if (likePatch.op === 'add') {
      newPucket.patches = newPucket.patches.filter(patch => {
        return !patch.path.startsWith(likePatch.path)
      })
    }

    const newMenu = loadEditMenu(basePucket, newPucket)

    // commitPucket(state.restaurantId, state.pucketId, newPucket)

    setState({ ...state, pucket: newPucket, menu: newMenu })
    setUnsaved(true)
  }

  const addNewDish = sectionId => {
    const highestSectionIndex = Math.max(...Object.entries(state.menu.sections[sectionId].dishes).map(([key, dish]) => {
      return dish['_new_index'] ?? dish['index'] ?? 1
    }))

    const index = (parseInt(highestSectionIndex) || 0) + 1000

    addPatch({
      op: 'add',
      path: `/sections/${sectionId}/dishes/${uuidv4()}`,
      value: {
        title: '',
        description: '',
        price: '',
        index: index
      }
    })
  }

  const addNewSection = () => {
    addPatch({
      op: 'add',
      path: `/sections/${uuidv4()}`,
      value: {
        title: '',
        dishes: {}
      }
    })
  }

  const setSectionTitle = (sectionIndex, value) => {
    if (value !== undefined && value.length && value.length > 0) {
      addPatch({
        op: 'add',
        path: `/sections/${sectionIndex}/title`,
        value: value
      })
    } else {
      removePatch({
        op: 'add',
        path: `/sections/${sectionIndex}/title`
      })
    }
  }

  const removeSection = (sectionIndex, section) => {
    const isRemoved = section._removed_
    const isNew = section._new_

    if (isNew) {
      if (!window.confirm(`Är du säker på att du vill ta bort kategorin?`)) {
        return
      }
      removePatch({
        op: 'add',
        path: `/sections/${sectionIndex}`
      })
    } else {
      if (isRemoved) {
        removePatch({
          op: 'remove',
          path: `/sections/${sectionIndex}`
        })
      } else {
        addPatch({
          op: 'remove',
          path: `/sections/${sectionIndex}`
        })
      }
    }
  }


  // if (token === null) {

  //   const submit = e => {
  //     const email = e.target[0].value
  //     const pass = e.target[1].value

  //     axios
  //       .post('login', {
  //         email: email,
  //         password: pass
  //       })
  //       .then(response => {
  //         const token = response.data.token
  //         writeCookie('token', token)
  //         fetchData(token)
  //       })
  //   }

  //   return (<div className={'app'}>
  //     <form onSubmit={submit} action='javascript:void(0);'>
  //       <input name='email' type='text' /* value='carl@ostwilkens.se' */ />
  //       <input name='password' type='password' /* value='callewilkens' */ />
  //       <input type='submit' value='submit' />
  //     </form>
  //   </div>)
  // }

  const handleDragEnd = event => {
    const { active, over } = event

    if (active.id === over.id) {
      return
    }

    const { index: oldIndex, sectionIndex: oldSectionIndex } = active.data.current
    let { index, nextIndex, previousIndex, sectionIndex: newSectionIndex } = over.data.current
    index = parseInt(index)
    nextIndex = parseInt(nextIndex)
    previousIndex = parseInt(previousIndex)

    if (oldSectionIndex !== newSectionIndex) {
      console.log("wrong section bro")
      return
    }

    let newIndex;
    if (index > oldIndex) {
      newIndex = index + (nextIndex - (index)) / 2
    } else {
      newIndex = previousIndex + (index - previousIndex) / 2
    }

    const patch = {
      op: 'add',
      path: `/sections/${newSectionIndex}/dishes/${active.id}/index`,
      value: parseInt(newIndex)
    }

    addPatch(patch)
  }

  // const logout = () => {
  //   removeCookie('token')
  //   window.location.reload()
  // }

  const exportPucket = () => {
    console.log(state.pucket)

    fileDownload(JSON.stringify(state.pucket), `${state.pucketId}-${Date.now() - 1621792850000}.pucket`)
  }

  const importPucket = (e) => {
    if (e.target.files.length > 0) {
      var reader = new FileReader();
      reader.onload = (e) => {
        const importedPucket = JSON.parse(e.target.result)
        const newMenu = loadEditMenu(basePucket, importedPucket)
        commitPucket(state.restaurantId, state.pucketId, importedPucket)

        setState(state => ({
          ...state,
          pucket: importedPucket,
          menu: newMenu
        }))
        setUnsaved(true)
      }

      const file = e.target.files[0]
      reader.readAsText(file);
    }
  }

  const undo = () => {
    if (state.pucket.patches.length > 0) {
      const popped = state.pucket.patches.pop()
      console.log("undo", popped)
      state.pucket.patches.pop()
      const newMenu = loadEditMenu(basePucket, state.pucket)
      commitPucket(state.restaurantId, state.pucketId, state.pucket)

      setState(state => ({
        ...state,
        pucket: state.pucket,
        menu: newMenu
      }))
      setUnsaved(true)
    }
  }

  const save = () => {
    commitPucket(state.restaurantId, state.pucketId, state.pucket)
  }

  const pickPucket = (pucket_id) => {
    // setState(state => ({
    //   ...state,
    //   pucket: newEmptyPucket()
    // }))

    setBasePucket(newEmptyPucket())
    setCurrentPucketId(pucket_id)
  }

  const newPucket = () => {
    const isNone = basePucketId == ''

    if (isNone) {
      const newPucket = newStartingPucket()
      const newPucketId = uuidv4()

      setPuckets(puckets => {
        return [...(puckets || []), { id: newPucketId, name: 'Ny meny' }]
      })

      setState(state => {
        state.pucket = newPucket
        state.pucketId = newPucketId
        state.menu = loadEditMenu(basePucket, newPucket)

        commitPucket(state.restaurantId, state.pucketId, state.pucket)

        return { ...state }
      })
      setUnsaved(true)

      setCurrentPucketId(newPucketId)
    } else {
      console.log(basePucketId)
      const basePucketJson = JSON.parse(basePucketId)
      console.log(`base pucket`, basePucketJson)

      const url = `restaurants/${basePucketJson.restaurantId}/puckets/${basePucketJson.pucketId}`

      axios
        // .get('restaurants/<restaurant_id>/puckets/' + basePucketId, {
        .get(url, {
          headers: headers()
        })
        .then(response => {
          const newBasePucket = response.data
          const newPucket = newEmptyPucket()
          newPucket.based_on = [basePucketJson.pucketId]
          const newPucketId = uuidv4()

          setBasePucket(newBasePucket)

          setPuckets(puckets => {
            return [...puckets, { id: newPucketId, name: 'Ny meny' }]
          })

          setState(state => {
            state.pucket = newPucket
            state.pucketId = newPucketId
            state.menu = loadEditMenu(newBasePucket, newPucket)

            commitPucket(state.restaurantId, state.pucketId, state.pucket)

            return { ...state }
          })
          setUnsaved(true)

          setCurrentPucketId(newPucketId)

          //         const newBasePucket = newEmptyPucket()
          //         const newMenu = loadEditMenu(newBasePucket, state.pucket)
          //         const newPucket = { ...state.pucket, based_on: [] }
          //         setBasePucket(newBasePucket)
          //         commitPucket(state.restaurantId, state.pucketId, newPucket)
          //         setState(state => ({
          //           ...state,
          //           pucket: newPucket,
          //           menu: newMenu
          //         }))
          //       } else {
          //         const newBasePucket = response.data
          //         const newMenu = loadEditMenu(newBasePucket, state.pucket)
          //         const newPucket = { ...state.pucket, based_on: [basePucketId] }
          //         setBasePucket(newBasePucket)
          //         commitPucket(state.restaurantId, state.pucketId, newPucket)
          //         setState(state => ({
          //           ...state,
          //           pucket: newPucket,
          //           menu: newMenu
          //         }))
          //       }
        })
    }
  }

  // const latestPatch = state.pucket.patches[state.pucket.patches.length - 1]

  // console.log(state.pucket.name)

  const onNameChanged = (e) => {
    setState(state => {
      state.pucket.name = e.target.value
      return { ...state }
    })
    setUnsaved(true)
  }

  const confirmDeletePucket = () => {
    if (window.confirm("Vill du verkligen ta bort menyn? Detta går inte att ångra.")) {
      deletePucket()
    }
  }

  // const onBasedOnChanged = (e) => {
  //   console.log("onBasedOnChanged")
  //   const basePucketId = e.target.value
  //   const isNone = basePucketId == ''

  //   axios
  //     .get('restaurants/' + restaurantId + '/puckets/' + basePucketId, {
  //       headers: headers()
  //     })
  //     .then(response => {
  //       if (isNone) {
  //         const newBasePucket = newEmptyPucket()
  //         const newMenu = loadEditMenu(newBasePucket, state.pucket)
  //         const newPucket = { ...state.pucket, based_on: [] }
  //         setBasePucket(newBasePucket)
  //         commitPucket(state.restaurantId, state.pucketId, newPucket)
  //         setState(state => ({
  //           ...state,
  //           pucket: newPucket,
  //           menu: newMenu
  //         }))
  //       } else {
  //         const newBasePucket = response.data
  //         const newMenu = loadEditMenu(newBasePucket, state.pucket)
  //         const newPucket = { ...state.pucket, based_on: [basePucketId] }
  //         setBasePucket(newBasePucket)
  //         commitPucket(state.restaurantId, state.pucketId, newPucket)
  //         setState(state => ({
  //           ...state,
  //           pucket: newPucket,
  //           menu: newMenu
  //         }))
  //       }
  //     })
  //     .catch(e => {
  //       console.log("pucket doesn't exist on server yet")
  //     })
  // }

  const onBasedOnChanged = (e) => {
    setBasePucketId(e.target.value)
  }

  return (
    <div className='pucket-app'>

      <div className='pucket-sidebar'>
        <div className='pucket-sidebar-title'>Menyer</div>
        <div className='pucket-sidebar-items'>
          {puckets &&
            puckets.map(({ id, name }) => (
              <label key={id} className={`pucket-sidebar-item ${currentPucketId === id ? 'active' : 'inactive'}`}>

                { currentPucketId === id ? 
                    <input type="text" placeholder="Namn" value={state.pucket.name || ''} onChange={onNameChanged} /> :
                    (name || id.substring(0, 4))
                }

                { currentPucketId === id && <button className="remove-menu-button" onClick={confirmDeletePucket}>🗑️</button> }

                <input checked={currentPucketId === id} type="radio" key={id} value={id} onChange={(e) => pickPucket(e.target.value)} />
              </label>
            ))
          }
        </div>
        <button onClick={newPucket}>Ny meny</button><br/>
        {/* <button onClick={confirmDeletePucket}>Ta bort meny</button><br/> */}
        <button className={'new-section-button'} onClick={() => addNewSection(state.menu.sections.length + 1)}>
          Ny kategori
        </button>
      </div>

      <div className='pucket-temp-container'>


        {/* <div className='pucket-head'> */}
        {/* {puckets && state.pucket && <select onChange={onBasedOnChanged}>
          <option value="">-</option>
          {puckets.map(({ id, name }) => (<option key={id} value={`{"restaurantId":"${window.restaurantId}","pucketId":"${id}"}`}>{name || id.substring(0, 4)}</option>))}
          <option key="0c05c0cf-0686-4bf7-8dc7-c50a8bca636d" value={`{"restaurantId":"57a1a96a-80d8-4696-8783-d5dd30c574a5","pucketId":"0c05c0cf-0686-4bf7-8dc7-c50a8bca636d"}`}>Hudik Meny 1</option>
        </select>} */}


        {/* <button onClick={confirmDeletePucket}>Ta bort</button> */}
        {/* {puckets && <select className='pucket-select' onChange={(e) => pickPucket(e.target.value)} value={currentPucketId}>
            {puckets.map(({ id, name }) => (<option key={id} value={id}>{name || id.substring(0, 4)}</option>))}
          </select>} */}



        {/* {puckets && state.pucket && <select onChange={onBasedOnChanged} value={state.pucket.based_on[0] || ""}>
        <option value="">-</option>
        {puckets.filter(p => p.id != currentPucketId).map(({ id, name }) => (<option key={id} value={id}>{name || id.substring(0, 4)}</option>))}
      </select>} */}

        {/* <input type="text" placeholder="Namn" value={state.pucket.name || ''} onChange={onNameChanged} onBlur={save} /> */}
        {/* <button onClick={exportPucket}>Exportera pucket</button> */}
        {/* <input type="file" onChange={importPucket} accept=".pucket"></input> */}
        {/* <button onClick={undo}>Ångra</button> */}
        {/* <pre style={{ paddingBottom: '50px', width: '450px', fontSize: '12px' }}>
        {JSON.stringify(state.pucket, null, ' ')}
      </pre> */}
        {/* </div> */}
        <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
          {state.menu.sections && <div className={'menu'}>
            {/* <div className={'menu-head'}>
              <h3>{state.pucket.name}</h3>
              <div>
                <a className="active">✏️</a>
                <a>📺</a>
              </div>
            </div> */}
            {Object.entries(state.menu.sections)
              .filter(([key, value]) => key !== '_new_')
              .map(([sectionId, section]) => (
                <Section
                  key={`${state.menu.id}:${sectionId}`}
                  sectionId={sectionId}
                  section={section}
                  removeSection={removeSection}
                  setSectionTitle={setSectionTitle}
                  addPatch={addPatch}
                  removePatch={removePatch}
                  addNewDish={addNewDish}
                />
              ))}
            {/* <button
              className={'new-section-button'}
              onClick={() => addNewSection(state.menu.sections.length + 1)}
            >
              Ny kategori
            </button> */}
          </div>}
        </DndContext>
      </div>

      <div className={`pucket-save ${unsaved ? '' : 'hidden'}`}>
        <button onClick={save} disabled={!unsaved}>Spara</button>
      </div>
    </div>
  )
}

export default MenuEditor
