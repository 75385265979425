import styled from 'styled-components'

const Select = styled.select`
    margin: 8px 4px;
    border-radius: 6px;
    height: 32px;
    padding: 0px 6px 0px 6px;
    font-weight: bold;
    font-family: inherit;
`

export default Select 
