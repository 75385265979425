import bjorn from '../img/bjorn.jpeg'
import { PrimaryButton } from './Button'

const OrderDeviceCTA = () => {
    return <div className='order-device-cta'>
        <p className='order-device-cta-head'>Ni har inte ännu några skärmar kopplade till Menui.<br />Det kan Björn ändra på!</p>
        <img className='order-device-bjorn' src={bjorn} />
        <a href="tel:+4676807788">
            <PrimaryButton>Ring Björn<br />076-6807788</PrimaryButton>
        </a>
        <p className='order-device-cta-price'>400kr/mån/skärm</p>
    </div>
}

export default OrderDeviceCTA