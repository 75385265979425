import jsonpatch from 'fast-json-patch'

export function newStartingPucket() {
  return {
    name: 'Ny Meny',
    based_on: [],
    patches: [
      {
        op: 'add',
        path: '/sections',
        value: {}
      }
    ]
  }
}

export function newEmptyPucket() {
  return { based_on: [], patches: [] }
}

export function getPropertyStatus({ isRemoved, baseValue, newValue }) {
  if (isRemoved) {
    return 'removed'
  } else if (baseValue && newValue) {
    return 'edited'
  } else if (baseValue && !newValue) {
    return 'unedited'
  } else if (!baseValue && newValue) {
    return 'added'
  } else {
    // throw Error('unknown property state')
    return 'added'
  }
}

export function loadEditMenu(basePucket, newPucket) {
  let menu = {}

  if (newPucket.based_on.length > 0 && basePucket.patches.length === 0) {
    return menu
  }

  jsonpatch.applyPatch(menu, jsonpatch.deepClone(basePucket.patches))

  const patchesForEditing = newPucket.patches.map(patch => {
    let value

    if (typeof patch.value === 'object') {
      value = {}
      Object.assign(value, patch.value)
    } else {
      value = patch.value
    }

    let newPatch = {
      op: patch.op,
      path: patch.path,
      value: value
    }

    const isValueType =
      typeof patch.value !== 'object' && !Array.isArray(patch.value)
    const isArray = Array.isArray(patch.value)

    if (patch.op === 'add' && isArray) {
    } else if (patch.op === 'add' && isValueType) {
      const i = patch.path.lastIndexOf('/')
      newPatch.path =
        patch.path.slice(0, i + 1) + '_new_' + patch.path.slice(i + 1)
    } else if (patch.op === 'add' && !isValueType) {
      const exclude = ['dishes', 'sections']

      newPatch.value = Object.fromEntries(
        Object.entries(newPatch.value).map(([key, value]) =>
          exclude.includes(key) ? [key, value] : ['_new_' + key, value]
        )
      )

      newPatch.value._new_ = true
    } else if (patch.op === 'replace' && isValueType) {
      newPatch.op = 'add'
      const i = patch.path.lastIndexOf('/')
      newPatch.path =
        patch.path.slice(0, i + 1) + '_new_' + patch.path.slice(i + 1)
    } else if (patch.op === 'remove') {
      // supports only object value
      // as we can't determine if the target value is of valueType... right?
      newPatch.path = newPatch.path + '/_removed_'
      newPatch.op = 'add'
      newPatch.value = true
    } else {
      throw Error('not yet implemented')
    }

    return newPatch
  })

  const errors = jsonpatch.validate(
    jsonpatch.deepClone(patchesForEditing),
    menu
  )

  if (errors) {
    console.error('jsonpatch error', errors)
  }

  jsonpatch.applyPatch(menu, jsonpatch.deepClone(patchesForEditing))

  return menu
}

export function writeCookie(name, value) {
  document.cookie = `${name}=${value}; Max-Age=86000`;
}

export function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function removeCookie(name) {
  document.cookie = `${name}=; Max-Age=-99999999;`;
}
