
import { useDroppable, useDraggable } from '@dnd-kit/core';
import GenericProperty from './GenericProperty'

const Item = ({ id, item, addPatch, removePatch, sectionIndex, itemIndex }) => {
  const indexProperty = item['_new_index'] ?? item['index'] ?? 1

  const {
    attributes,
    listeners,
    setNodeRef: setNodeRefDraggable,
    over,
    isDragging,
    node
  } = useDraggable({
    id: id,
    data: { index: indexProperty, sectionIndex: sectionIndex }
  })

  let nextIndex = 0;
  let previousIndex = 0;
  if (node.current) {
    if (node.current.parentNode.nextSibling && node.current.parentNode.nextSibling.style.order) {
      nextIndex = node.current.parentNode.nextSibling.style.order
    } else {
      nextIndex = parseInt(indexProperty) + 2000
    }
    if (node.current.parentNode.previousSibling && node.current.parentNode.previousSibling.style.order) {
      previousIndex = node.current.parentNode.previousSibling.style.order
    } else {
      previousIndex = parseInt(indexProperty) - 2000
    }
  }

  const { setNodeRef: setNodeRefDroppable } = useDroppable({
    id: id,
    data: { index: indexProperty, sectionIndex: sectionIndex, nextIndex: nextIndex, previousIndex: previousIndex }
  })

  const style = (over && isDragging) ? {
    opacity: 0.5
  } : {}

  let previewStyleOrder = indexProperty;
  if (over && isDragging) {
    const overIndex = parseInt(over.data.current.index)

    if (overIndex > indexProperty) {
      previewStyleOrder = overIndex + 1
    } else {
      previewStyleOrder = overIndex - 1
    }

  }

  const wrapperStyle = {
    order: previewStyleOrder
  }

  const isNew = item._new_ ? true : false
  const isRemoved = item._removed_ ? true : false
  const dishId = itemIndex

  const ordering = {
    title: 1,
    description: 2,
    price: 3
  }

  // list unique property names (without _new_)
  const propertyNames = Object.keys(item).map(key =>
    key.replace('_new_', '').replace('_removed_', '')
  )
  const uniquePropertyNames = [...new Set(propertyNames)]
    .filter(x => x.length > 0 && x !== 'dish_id' && x !== 'index')
    .sort((a, b) => ordering[a] - ordering[b])

  const setPropertyValue = (sectionIndex, itemIndex, name, value) => {
    if (value !== undefined && value.length && value.length > 0) {
      addPatch({
        op: 'add',
        path: `/sections/${sectionIndex}/dishes/${itemIndex}/${name}`,
        value: value
      })
    } else {
      removePatch({
        op: 'add',
        path: `/sections/${sectionIndex}/dishes/${itemIndex}/${name}`
      })
    }
  }

  const onClickRemove = () => {
    if (isNew) {
      if (!window.confirm(`Är du säker på att du vill ta bort rätten?`)) {
        return
      }

      removePatch({
        op: 'add',
        path: `/sections/${sectionIndex}/dishes/${itemIndex}`
      })
    } else {
      if (isRemoved) {
        removePatch({
          op: 'remove',
          path: `/sections/${sectionIndex}/dishes/${itemIndex}`
        })
      } else {
        addPatch({
          op: 'remove',
          path: `/sections/${sectionIndex}/dishes/${itemIndex}`
        })
      }
    }
  }

  const status = isRemoved ? 'removed' : isNew ? 'new' : 'none'

  return (
    <div ref={setNodeRefDroppable} style={wrapperStyle} className='section-item'>
      <div
        className={`item item-status--${status}`}
        ref={setNodeRefDraggable}
        style={style}
        {...listeners}
        {...attributes}
      >
        <div className={'remove-item-button'} onClick={onClickRemove}>
          ❌
        </div>
        {uniquePropertyNames.map(key => (
          <GenericProperty
            key={key}
            name={key}
            baseValue={item[key]}
            newValue={item['_new_' + key]}
            isRemoved={item['_removed_' + key]}
            onSave={value =>
              setPropertyValue(sectionIndex, itemIndex, key, value)
            }
            dishId={dishId}
          />
        ))}
      </div>
    </div>
  )
}

export default Item
