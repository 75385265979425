import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import loaderImg from './img/loading.gif'
import OrderDeviceCTA from './components/OrderDeviceCTA'
import { SmallButton } from './components/Button'
import Select from './components/Select'
import { useStore } from './store'

const LayoutElement = ({ type, ...props }) => {
  if (type === 0) {
    return <Content {...props} />
  } else if (type === 1) {
    return <Split {...props} />
  } else {
    return 'unknown layout element type'
  }
}

const Content = ({
  content,
  style,
  path,
  onLayoutElementChanged,
  onLayoutElementSplit,
  onLayoutElementRemove,
  onLayoutElementStartResize,
  menus,
  files,
  onUploadComplete
}) => {
  const { resource_type, resource_url, text, menu_id, section_id, type } = content
  const content_type = parseInt(type)

  const onTextChange = (value) => {
    onLayoutElementChanged([...path, 'content', 'text'], value)
  }

  const onSectionChange = (value) => {
    const [menuId, sectionId] = JSON.parse(value)
    onLayoutElementChanged([...path, 'content', 'section_id'], sectionId)
    onLayoutElementChanged([...path, 'content', 'menu_id'], menuId)
  }

  const onResourceUrlChange = ({ resource_url, resource_type }) => {
    onLayoutElementChanged([...path, 'content', 'resource_url'], resource_url)
    onLayoutElementChanged([...path, 'content', 'resource_type'], resource_type)
  }

  let inner
  switch (content_type) {
    case 0:
      inner = <SectionContent menu_id={menu_id} section_id={section_id} path={path} menus={menus} onChange={onSectionChange} />
      break
    case 1:
      inner = <TextContent text={text} path={path} onChange={onTextChange} />
      break
    case 2:
      inner = (
        <ResourceContent
          resource_url={resource_url}
          resource_type={resource_type}
          path={path}
          onChange={onResourceUrlChange}
          files={files}
          onUploadComplete={onUploadComplete}
        />
      )
      break
  }

  const onContentTypeChange = (e) => {
    onLayoutElementChanged([...path, 'content', 'type'], e.target.value)
  }

  const onAddBottom = (e) => {
    onLayoutElementSplit(path, 0)
  }

  const imgPreview = (type == 2 && resource_type == 0 && resource_url && resource_url.length > 0)
  if (imgPreview) {
    const thumbUrl = `https://thumb.quest/?w=800&h=800&src=${resource_url}`
    style = { ...style, backgroundImage: `url(${thumbUrl})` }
  }

  return (
    <div className='layout-content' style={style}>
      <select className='layout-select-type'
        value={content_type}
        onChange={onContentTypeChange}>
        <option value={0}>Kategori</option>
        <option value={1}>Text</option>
        <option value={2}>Media</option>
      </select>
      {inner}
      <button className='layout-add-right'
        onClick={() => onLayoutElementSplit(path, 1)}>+</button>
      <button className='layout-add-bottom'
        onClick={onAddBottom}>+</button>
      <button className='layout-remove' onClick={() => onLayoutElementRemove(path)}>🗑️</button>
    </div>
  )
}

const toValue = (menuId, sectionId) => {
  return JSON.stringify([menuId, sectionId])
}

const SectionContent = ({ menu_id, section_id, path, menus, onChange }) => {

  const renderMenu = (menu) => {
    return <optgroup label={menu.name || menu.id.substring(0, 8)} key={menu.id}>
      {menu.sections && menu.sections.map(section => renderSection(section, menu))}
    </optgroup>
  }

  const renderSection = (section, menu) => {
    return <option value={toValue(menu.id, section.id)} key={section.id}>{section.title}</option>
  }

  return <select
    className={'layout-sections'}
    value={toValue(menu_id, section_id)}
    onChange={(e) => {
      onChange(e.target.value)
    }}
  >
    {menus.map(renderMenu)}
  </select>
}

const TextContent = ({ text, path, onChange }) => {
  const [localValue, setLocalValue] = useState(text)

  const onInputChange = (e) => {
    setLocalValue(e.target.value)
    onChange(e.target.value)
  }

  return <div>
    <textarea className={'layout-text-input'} type='text' value={localValue} onChange={onInputChange} />
  </div>
}

const ResourceContent = ({ resource_url, resource_type, path, onChange, files, onUploadComplete }) => {
  const [localValue, setLocalValue] = useState({
    resource_url: resource_url,
    resource_type: resource_type || 0
  })
  const [isUploading, setIsUploading] = useState(false)
  const showModal = useStore(state => state.showModal)
  const closeModal = useStore(state => state.closeModal)

  const upladingModal = <div className="layout-uploading-file-modal">Laddar upp...<br /><img src={loaderImg} /></div>

  const onResourceUrlChange = (new_url) => {
    const newValue = {
      ...localValue,
      resource_url: new_url
    }

    setLocalValue(newValue)
    onChange(newValue)
  }

  const onResourceTypeChange = (e) => {
    const { type, url } = JSON.parse(e.target.value)

    const newValue = {
      ...localValue,
      resource_type: type,
      resource_url: url
    }

    setLocalValue(newValue)
    onChange(newValue)
  }

  const onFileChange = (e) => {
    if (e.target.files.length === 0) {
      console.log('No files selected')
      return
    }

    const file = e.target.files[0]
    console.log("starting upload...", file)
    setIsUploading(true)
    showModal(upladingModal)

    let mimetype = file.type
    if (mimetype === "" && file.name.endsWith(".mkv")) {
      mimetype = "video/x-matroska"
    }

    axios.post(`files/upload/${file.name}`, file, {
      headers: {
        'Content-Type': mimetype
      }
    }).then(r => {
      const { file_id, name, url } = r.data

      setIsUploading(false)
      closeModal()
      onResourceUrlChange(url)
      console.log("upload complete!", r.data)
      onUploadComplete(r.data)
    })
      .catch(x => {
        console.error(x)
        setIsUploading(false)
        closeModal()
      })
  }

  const imgPreview = (resource_type == 0 && resource_url && resource_url.length > 0)

  return <div>
    <select className='layout-resource-type'
      value={JSON.stringify({ type: resource_type, url: resource_url })}
      onChange={onResourceTypeChange}>
      <option value={JSON.stringify({ type: 0 })}>Ny bild</option>
      <option value={JSON.stringify({ type: 1 })}>Ny video</option>
      <optgroup label="Tidigare filer">
        {files.map(f => {
          const { file_id, name, url } = f
          const isVideo = name.endsWith(".mp4")
          const type = isVideo ? 1 : 0

          return <option key={file_id} value={JSON.stringify({ type, url })}>{name}</option>
        })}
      </optgroup>
    </select>
    <br />
    {/* <textarea className={'layout-resource-url'} type='text' value={localValue.resource_url} onChange={(e) => {onResourceUrlChange(e.target.value)}} /> */}
    {(!resource_url || resource_url === "") && <input className="layout-resource-file" type="file" /*accept='image/*'*/ onChange={onFileChange} style={{ maxWidth: '85px' }} />}
    {/* {imgPreview && <ThumbImg src={localValue.resource_url} />} */}

  </div>
}

const ThumbImg = ({ src, width, height }) =>
  <img src={`https://thumb.quest/?src=${src}&w=${width}&h=${height}`} />


const Divider = ({ orientation, ...props }) => {
  const className = orientation === 1 ? 'layout-divider-h' : 'layout-divider-v'

  return <div className={'layout-divider ' + className} {...props}></div>
}

const Split = ({
  split_position,
  orientation,
  first,
  second,
  style,
  path,
  setLayoutPath,
  onLayoutElementStartResize,
  onLayoutElementChanged,
  onLayoutElementSplit,
  onLayoutElementRemove,
  menus,
  files,
  onUploadComplete
}) => {
  const containerRef = useRef()
  const flexDirection = orientation === 0 ? 'column' : 'row'
  const className = orientation === 1 ? 'layout-split-h' : 'layout-split-v'

  const onDividerMouseDown = (e) => {
    const rect = containerRef.current.getBoundingClientRect()
    onLayoutElementStartResize(path, e.clientX, e.clientY, orientation, rect)
  }

  return (
    <div className={'layout-split ' + className}
      style={{ flexDirection, ...style }}
      ref={containerRef}>
      {first && (
        <LayoutElement
          {...first}
          path={[...path, 'first']}
          style={{ flex: split_position }}
          setLayoutPath={setLayoutPath}
          onLayoutElementStartResize={onLayoutElementStartResize}
          onLayoutElementChanged={onLayoutElementChanged}
          onLayoutElementSplit={onLayoutElementSplit}
          onLayoutElementRemove={onLayoutElementRemove}
          menus={menus}
          files={files}
          onUploadComplete={onUploadComplete}
        />
      )}
      <Divider
        split_position={split_position}
        orientation={orientation}
        onMouseDown={onDividerMouseDown}
      />
      {second && (
        <LayoutElement
          {...second}
          path={[...path, 'second']}
          style={{ flex: 1 - split_position }}
          setLayoutPath={setLayoutPath}
          onLayoutElementStartResize={onLayoutElementStartResize}
          onLayoutElementChanged={onLayoutElementChanged}
          onLayoutElementSplit={onLayoutElementSplit}
          onLayoutElementRemove={onLayoutElementRemove}
          menus={menus}
          files={files}
          onUploadComplete={onUploadComplete}
        />
      )}
    </div>
  )
}

const LayoutEditor = ({ token }) => {
  const restaurantId = window.restaurantId
  const [currentLayout, setCurrentLayout] = useState()
  const [dragState, setDragState] = useState({
    isDragging: false,
    currentX: null,
    currentY: null,
    startX: null,
    startY: null,
    path: '',
    orientation: null,
    rect: {}
  })
  const [menus, setMenus] = useState([])
  const [layouts, setLayouts] = useState(null)
  const [devices, setDevices] = useState(null)
  const [files, setFiles] = useState(null)
  const editorRef = useRef()
  const [rotation, setRotation] = useState(0) // 0 or 1
  const [previewImg, setPreviewImg] = useState(null)

  // useEffect(() => {
  //   if (!currentLayout || !devices || !Array.isArray(devices)) {
  //     return
  //   }

  //   const layout_id = currentLayout.layout_id
  //   const device_id = devices[0].device_id

  //   axios
  //     .get(`/devices/${device_id}/token`)
  //     .then(r => {
  //       const bearer_token = r.data.token

  //       var body = {}
  //       body[`layouts/${layout_id}`] = currentLayout
  //       body[`devices/${device_id}/schedule`] = [{
  //         "opening_hours": "24/7",
  //         "layout_id": layout_id,
  //         "priority": 1
  //       }]

  //       console.log(body)

  //       axios
  //         .post("https://render.menui.se/render", body, { 
  //           responseType: 'arraybuffer',
  //           headers: { 
  //             Authorization: `Bearer ${bearer_token}`,
  //             "X-Rotation": "-90",
  //           } })
  //         .then(r => {
  //           const img = Buffer.from(r.data, 'binary').toString('base64')
  //           setPreviewImg(img)
  //         })
  //     })

    

  // }, [currentLayout, devices])



  const headers = () => ({ Authorization: 'Bearer ' + token })

  const fetchData = (token) => {
    axios
      .get('restaurants/<restaurant_id>', {
        headers: headers()
      })
      .then(response => {
        const menus = response.data.menus
        setMenus(menus)
      })

    axios
      .get('restaurants/<restaurant_id>/layouts', {
        headers: headers()
      })
      .then(response => {
        const data = response.data
        data.sort((a, b) => a.layout_id > b.layout_id ? 1 : -1)

        setLayouts(data)

        if (!currentLayout) {
          setCurrentLayout(response.data[0])
        }
      })

    axios.get('files', {
      headers: headers()
    })
      .then(response => {
        const { data } = response

        data.reverse()

        let existingNames = []
        let files = []
        for (const file of data) {
          // data.sort((a, b) => a.device_id > b.device_id ? 1 : -1)

          const nameTaken = existingNames.includes(file.name)
          existingNames.push(file.name)

          if (!nameTaken) {
            files.push(file)
          }
        }

        setFiles(files)
      })

    axios.get('restaurants/<restaurant_id>/devices')
      .then(response => {
        const data = response.data
        data.sort((a, b) => a.device_id > b.device_id ? 1 : -1)
        setDevices(data)
      })
  }

  useEffect(() => {
    fetchData(token)
  }, [token /*, currentLayoutId */])

  const readLayoutPath = (path) => {
    let current = currentLayout.data
    for (let i = 0; i < path.length - 1; i++) {
      const key = path[i]
      current = current[key]
    }

    if (path.length === 0) {
      return currentLayout.data
    }

    return current[path[path.length - 1]]
  }

  const setLayoutPath = (path, value) => {
    let current = currentLayout.data
    for (let i = 0; i < path.length - 1; i++) {
      const key = path[i]
      current = current[key]
    }

    if (path.length === 0) {
      setCurrentLayout(current => ({ ...current, data: { ...value } }))
    } else {
      current[path[path.length - 1]] = value
      setCurrentLayout({ ...currentLayout })
    }
  }

  const onLayoutElementChanged = (path, value) => {
    console.log('onLayoutElementChanged', path, value)
    setLayoutPath(path, value)
  }
  const onLayoutElementStartResize = (path, x, y, orientation, rect) => {
    console.log('onLayoutElementStartResize', path, x, y)
    setDragState((state) => {
      return {
        ...state,
        isDragging: true,
        path: path,
        startX: x,
        startY: y,
        orientation: orientation,
        rect: rect
      }
    })
  }
  const onLayoutElementMouseMoved = (x, y) => {
    if (dragState.isDragging) {
      setDragState((state) => {
        return { ...state, currentX: x, currentY: y }
      })

      const rect = dragState.rect

      const getRelativePosition = () => {
        if (dragState.orientation === 1) {
          return (x - rect.left) / (rect.right - rect.left)
        } else if (dragState.orientation === 0) {
          return (y - rect.top) / (rect.bottom - rect.top)
        } else {
          console.error("unknown orientation", dragState.orientation)
        }
      }

      let relativePosition = getRelativePosition()
      relativePosition = Math.round(relativePosition * 20.0) / 20.0

      setLayoutPath([...dragState.path, 'split_position'], relativePosition)
    }
  }
  const onLayoutElementStopResize = () => {
    console.log('onLayoutElementStopResize')
    if (dragState.isDragging) {
      setDragState((state) => {
        return { ...state, isDragging: false }
      })
    }
  }
  const onLayoutElementSplit = (path, orientation) => {
    console.log('onLayoutElementSplit', path, orientation)

    const first = readLayoutPath(path)
    const second = { type: 0, content: { type: 1, text: 'new' } }

    const newSplit = {
      first: first,
      second: second,
      split_position: 0.6,
      orientation: orientation,
      type: 1
    }

    setLayoutPath(path, newSplit)
  }

  const onLayoutElementRemove = (path) => {
    console.log('onLayoutElementRemove', path)

    const parentPath = path.slice(0, -1)

    const siblingPath = path[path.length - 1] === 'first' ?
      [...parentPath, 'second'] :
      [...parentPath, 'first']

    const siblingElement = readLayoutPath(siblingPath)

    // console.log(`parentPath: ${parentPath}; siblingPath: ${siblingPath};`)

    setLayoutPath(parentPath, siblingElement)
  }

  const save = () => {
    // const serializedLayout = {
    //   ...currentLayout,
    //   data: JSON.stringify(currentLayout.data)
    // }

    // // console.log(serializedLayout.data)

    console.log("saving...", currentLayout)

    axios
      .put('layouts/' + currentLayout.layout_id,
        currentLayout,
        {
          headers: headers()
        })
      .then(response => {
        console.log(response)

        if (response.status !== 200) {
          console.error(response)
        }
      })
  }

  const deleteLayout = () => {
    axios
      .delete('layouts/' + currentLayout.layout_id,
        {
          headers: headers()
        })
      .then(response => {
        console.log(response)

        if (response.status !== 200) {
          console.error(response)
        } else {
          window.location.reload()
        }
      })
  }

  const newLayout = () => {
    const newLayout = {
      layout_id: uuidv4(),
      restaurant_id: restaurantId,
      name: 'Ny layout',
      data: {
        type: 0, orientation: 1, split_position: 0.5, content: {
          resource_type: 0,
          type: 2
        }
      }
    }

    setCurrentLayout(newLayout)
    setLayouts(current => {
      return [...current, newLayout]
    })
  }

  const onNameChanged = (e) => {
    setCurrentLayout(current => {
      current.name = e.target.value
      return { ...current }
    })
  }

  const confirmDeleteLayout = () => {
    if (window.confirm("Vill du verkligen ta bort designen? Detta går inte att ångra.")) {
      deleteLayout()
    }
  }

  const onUploadComplete = (file) => {
    setFiles(files => [...files, file])
  }

  const rotate = () => {
    if (rotation === 0) {
      setRotation(1)
    } else {
      setRotation(0)
    }
  }

  const hasLoaded = devices !== null && layouts !== null && files != null

  const style = { backgroundImage: `url(data:image/png;base64,${previewImg})`} 

  return (
    <div className='layout-app'>
      <div className='layout-head'>
        {(hasLoaded && devices.length > 0) && <SmallButton onClick={newLayout}>Skapa ny</SmallButton>}
        {/* <button onClick={confirmDeleteLayout}>Ta bort</button> */}
        {(hasLoaded && layouts.length > 0) && <Select onChange={(e) => setCurrentLayout(layouts.find(l => l.layout_id == e.target.value))} defaultValue={currentLayout && currentLayout.layout_id}>
          {layouts.map(({ layout_id }) => (<option key={layout_id} value={layout_id}>{layouts.find(l => l.layout_id == layout_id).name || layout_id.substring(0, 8)}</option>))}
        </Select>}
        {/* {currentLayout && <input type="text" placeholder="Namn" value={currentLayout.name || ''} onChange={onNameChanged} />} */}
        {currentLayout && <SmallButton onClick={save}>Spara</SmallButton>}
        {currentLayout && <SmallButton onClick={rotate}>Rotera</SmallButton>}
      </div>
      {(hasLoaded && devices.length === 0) && <OrderDeviceCTA />}
      {(hasLoaded && currentLayout) && <div className={`layout-editor layout-editor-rot-${rotation}`}
        key={currentLayout.layout_id}
        ref={editorRef}
        onMouseUp={() => onLayoutElementStopResize()}
        onMouseMove={({ clientX, clientY }) => onLayoutElementMouseMoved(clientX, clientY)}
        style={style}>
        <LayoutElement
          {...currentLayout.data}
          path={[]}
          setLayoutPath={setLayoutPath}
          dragState={dragState}
          onLayoutElementChanged={onLayoutElementChanged}
          onLayoutElementStartResize={onLayoutElementStartResize}
          onLayoutElementMouseMoved={onLayoutElementMouseMoved}
          onLayoutElementSplit={onLayoutElementSplit}
          onLayoutElementRemove={onLayoutElementRemove}
          menus={menus}
          files={files}
          onUploadComplete={onUploadComplete}
        />
      </div>}
    </div>
  )
}

export default LayoutEditor
