import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { InlineSmallButton } from './components/Button'

const Slide = ({ files, slide, onFileChanged, onDurationChanged, onRemove, onMove }) => {
	const [isDropTarget, setIsDropTarget] = useState(null)

	function handleFileChange(event) {
		onFileChanged(slide, event.target.value);
	}

	function handleDurationChange(event) {
		onDurationChanged(slide, event.target.value);
	}

	function handleRemove(event) {
		onRemove(slide);
	}

	function getSelectedImageUrl() {
		let matchingFile = files?.find(f => f.file_id === slide.file.file_id);

		return matchingFile?.url;
	}

	function isSelectedVideo() {
		let matchingFile = files?.find(f => f.file_id === slide.file.file_id);

		return matchingFile?.content_type.startsWith('video/');
	}

	function onDrop(event) {
		event.preventDefault();

		let source = event.dataTransfer.getData("slide_id");

		onMove(source, slide.slide_id);
	}

	function onDragOver(event) {
		event.preventDefault();

		setIsDropTarget(true);
	}

	function onDragStart(ev) {
		ev.dataTransfer.setData("slide_id", slide.slide_id);
	}

	return <div className='slide-editor' draggable='true' onDrop={onDrop} onDragOver={onDragOver} onDragStart={onDragStart}>
		{!isSelectedVideo() && <img src={`https://thumb.quest/?src=${getSelectedImageUrl()}&crop=true`} style={{ maxWidth: 200, maxHeight: 200 }} />}
		<div>
			<select value={slide.file.file_id} onChange={handleFileChange}>
				{files?.map(f => {
					const { file_id, name, url } = f
					const isVideo = name.endsWith(".mp4")
					const type = isVideo ? 1 : 0

					return <option key={file_id} value={file_id}>{name}</option>
				})}
			</select>
			<div className='slide-editor-time'>
				Visas i
				<input type='number' value={slide.duration} onChange={handleDurationChange} />
				<span>sekunder</span>
			</div>
			<InlineSmallButton onClick={handleRemove}>Ta bort</InlineSmallButton>
		</div>
	</div>
}

const SlideshowEditor = () => {
	const [slides, setSlides] = useState(null)
	const [files, setFiles] = useState(null)
	const [isFileUploading, setIsFileUploading] = useState(false)
	const [rand, setRand] = useState(false)

	let guid = "c0afa567-c8ec-417e-ab53-effe7eeb8534";

	useEffect(() => {
		if (files === null) {
			axios.get('files')
				.then(response => {
					const { data } = response

					data.reverse()

					let existingNames = []
					let files = []
					for (const file of data) {
						const nameTaken = existingNames.includes(file.name)
						existingNames.push(file.name)

						if (!nameTaken) {
							files.push(file)
							// console.log(file)
						}
					}

					setFiles(files)
				})
		}

		if (slides === null) {
			axios.get('restaurants/<restaurant_id>/slideshows')
				.then(response => {
					const { data } = response

					let slideshow = data.find(slide => slide.slideshow_id === guid);

					setSlides(slideshow?.slides);
				});
		}
	})

	function save() {
		let slideshow = {
			name: "My Slides",
			slides: slides.map((slide, i) => {
				return {
					index: i,
					duration: slide.duration,
					file_id: slide.file.file_id
				};
			})
		};

		axios.put(`restaurants/<restaurant_id>/slideshows/${guid}`, slideshow);

		axios.get('restaurants/<restaurant_id>/layouts')
			.then(response => {
				const { data } = response;

				data.forEach(layout => {
					let newLayout = {
						...layout,
						data: {
							type: 0,
							content: {
								type: 3,
								slideshow: slides.map(s => {
									return {
										resource_type: s.file.content_type.startsWith('image/') ? 0 : 1,
										duration: s.duration * 1000,
										resource_url: s.file.url
									};
								})
							}
						}
					}

					axios.put(`layouts/${layout.layout_id}`, newLayout);
				});
			});
	}

	function addSlide() {
		let slide = {
			slide_id: uuidv4(),
			file: files[0],
			duration: 15
		};

		if (slides) {
			setSlides([...slides, slide]);
		}
		else {
			setSlides([slide]);
		}
	}

	function handleFileChange(slide, file_id) {
		let file = files.find(f => f.file_id === file_id);

		if (!file) {
			return;
		}

		let updatedSlides = slides.map(s => {
			if (s.slide_id === slide.slide_id) {
				return { ...s, file: file };
			}

			return s;
		});

		setSlides(updatedSlides);
	}

	function handleDurationChange(slide, duration) {
		let updatedSlides = slides.map(s => {
			if (s.slide_id === slide.slide_id) {
				return { ...s, duration: duration };
			}

			return s;
		});

		setSlides(updatedSlides);
	}

	function handleSlideRemove(slide) {
		let updatedSlides = slides.filter(s => s.slide_id !== slide.slide_id);

		setSlides(updatedSlides);
	}

	function onFileChange(event) {
		if (event.target.files.length === 0) {
			return;
		}

		const file = event.target.files[0];

		setIsFileUploading(true);

		axios.post(`files/upload/${file.name}`, file, {
			headers: {
				'Content-Type': file.type
			}
		})
			.then(r => {
				setFiles(null);
				setIsFileUploading(false);
			})
			.catch(x => {
				setIsFileUploading(false);
			});
	}

	function handleOnMove(sourceId, targetId) {
		let source = undefined;
		let target = undefined;

		for (let slide of slides) {
			if (slide.slide_id == sourceId) {
				source = slide;
			}

			if (slide.slide_id == targetId) {
				target = slide;
			}
		}

		if (!source || !target) {
			return;
		}

		let sourceIndex = source.index;

		source.index = target.index;
		target.index = sourceIndex;

		setRand(Math.random());
	}

	function showFileUploadDialog() {
		document
			.getElementById('slideshow-file-upload')
			.click()
	}

	return <div>
		<dialog id="modal" open={isFileUploading}>
			Din fil laddas upp...
		</dialog>
		{slides?.sort((s1, s2) => s1.index - s2.index)
			.map(slide => {
				return <div key={slide.slide_id}>
					<Slide files={files}
						slide={slide}
						onFileChanged={handleFileChange}
						onDurationChanged={handleDurationChange}
						onRemove={handleSlideRemove}
						onMove={handleOnMove}
					/>
				</div>
			})}
		<div>
			<InlineSmallButton onClick={save}>Spara</InlineSmallButton>
			<InlineSmallButton onClick={addSlide}>+ Lägg till</InlineSmallButton>
			<InlineSmallButton onClick={showFileUploadDialog}>+ Ladda upp bild / filmklipp</InlineSmallButton>
			<input id='slideshow-file-upload' style={{ display: 'none' }} type="file" onChange={onFileChange} />
		</div>
	</div>
}

export default SlideshowEditor
