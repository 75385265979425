import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import axios from 'axios'
import { readCookie } from './utils'

console.debug('ENV', process.env)
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL

// const restaurantId = '57a1a96a-80d8-4696-8783-d5dd30c574a5' // Tahmina AB
// const restaurantId = '4d8e731e-2780-4641-9ca6-1f08ba587b23' // Stengatan
const restaurantId = readCookie('restaurant_id')
console.log("restaurantId", restaurantId)
window.restaurantId = restaurantId

axios.interceptors.request.use(config => {
  config.url = config.url.replace("<restaurant_id>", restaurantId)
  return config
}, error => {
  return Promise.reject(error)
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
