import styled from 'styled-components'
import { ReactComponent as LogoSvg } from '../img/logo.svg'

const StyledLogoSvg = styled(LogoSvg)`
    margin-top: 12px;
    margin-bottom: 8px;
    width: 185px;
`

export const Logo = () => <StyledLogoSvg />
