import React, { useState, useRef, useEffect } from 'react'
import MenuEditor from './MenuEditor'
import LayoutEditor from './LayoutEditor'
import ScheduleEditor from './ScheduleEditor'
import ScheduleEditorText from './ScheduleEditorText'
import SlideshowEditor from './SlideshowEditor'
import { readCookie, writeCookie, removeCookie } from './utils'
import axios from 'axios'
import MainTheme from './MainTheme'
import { ThemeProvider } from 'styled-components'
import { LoginForm } from './components/LoginForm'
import { ReactComponent as FoodSvg } from './img/food.svg'
import { ReactComponent as ScheduleSvg } from './img/schedule.svg'
import { ReactComponent as SettingsSvg } from './img/settings.svg'
import { ReactComponent as ScreenSvg } from './img/screen.svg'
import { useStore } from './store'

const onSetToken = (token) => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

const App = () => {
  const isDirektOptik = window.restaurantId === "6b870351-b8c7-4bac-ae68-4cb4697ead70"
  const isScenkonst = window.restaurantId === "2f47bdd1-2cf5-451c-b5d1-781b3cb7e3e5"
  const urlParams = new URLSearchParams(window.location.search)
  const [tabIndex] = useState(() => {
    const urlTabIndex = parseInt(urlParams.get('tab'))

    if (Number.isInteger(urlTabIndex)) {
      return urlTabIndex
    } else {
      return isDirektOptik ? 2 : 1
    }
  })
  const [token, setToken] = useState(() => {
    const token = readCookie('token')
    onSetToken(token)
    return token
  })
  const [error, setError] = useState()
  const [restaurants, setRestaurants] = useState([])
  const modalContent = useStore(state => state.modalContent)

  const unsavedRef = useRef(useStore.getState().unsaved)
  useEffect(() => useStore.subscribe(
    state => (unsavedRef.current = state.unsaved)
  ), [])

  useEffect(() => {
    window.addEventListener("beforeunload", (e) => {
      if (!unsavedRef.current) {
        return undefined;
      }

      const msg = "Vill du lämna sidan utan att spara?";

      (e || window.event).returnValue = msg; // Gecko + IE
      return msg; // Gecko + Webkit, Safari, Chrome etc.
    })
  }, [])

  useEffect(() => {
    axios.get('user/restaurants').then(response => {
      if (response.data && response.data.length > 0) {
        console.log("found restaurants", response.data)
        setRestaurants(response.data)
      } else {
        console.error("no restaurants found!")
      }
    })
  }, [])

  const logout = () => {
    removeCookie('token')
    removeCookie('restaurant_id')
    window.location.reload()
  }

  const login = (e) => {
    const email = e.target[0].value
    const pass = e.target[1].value

    axios
      .post('login', {
        email: email,
        password: pass
      })
      .then(response => {
        const token = response.data.token
        setToken(token)
        onSetToken(token)
        writeCookie('token', token)

        axios.get('user/restaurants').then(response => {
          if (response.data && response.data.length > 0) {

            // 0 : {restaurant_id: 'b1417723-2382-4bc9-80ce-5efaf95b166c', name: 'Ljusdal Sushi'}
            // 1 : {restaurant_id: 'cbc12e67-90d5-4066-8878-cfa27f291006', name: 'Sundsvall Sushi'}
            // 2 : {restaurant_id: '57a1a96a-80d8-4696-8783-d5dd30c574a5', name: 'Hudik Sushi'}

            console.log("found restaurants", response.data)
            const restaurant_id = response.data[0].restaurant_id
            writeCookie('restaurant_id', restaurant_id)
            console.log("writing cookie restaurant_id", restaurant_id)
            window.location.reload()
          } else {
            console.error("no restaurants found!")
          }
        })
      })
      .catch(e => {
        console.error(e)
        if (e.response.data && e.response.data.title) {
          setError(e.response.data.title)
        } else {
          setError("Unknown error")
        }
      })
  }

  const onPickRestaurant = (e) => {
    const restaurant_id = e.target.value
    writeCookie('restaurant_id', restaurant_id)
    console.log("writing cookie restaurant_id", restaurant_id)
    window.location.reload()
  }

        // 0 : {restaurant_id: 'b1417723-2382-4bc9-80ce-5efaf95b166c', name: 'Ljusdal Sushi'}
        // 1 : {restaurant_id: 'cbc12e67-90d5-4066-8878-cfa27f291006', name: 'Sundsvall Sushi'}
        // 2 : {restaurant_id: '57a1a96a-80d8-4696-8783-d5dd30c574a5', name: 'Hudik Sushi'}
  

  return (
    <ThemeProvider theme={MainTheme}>
      {token === null ? <LoginForm onSubmit={login} errorMessage={error} /> :
        <>
          <nav>
            <select onChange={onPickRestaurant} value={window.restaurantId}>
              {restaurants.map((restaurant, index) => {
                return <option key={index} value={restaurant.restaurant_id}>{restaurant.name.replaceAll("Sushi", "").trim()}</option>
              })}
            </select>
            {!isDirektOptik && <a href="?tab=1" className={tabIndex === 1 ? `active` : ''}>
              <FoodSvg /><span>Menyer</span>
            </a>}
            <a href="?tab=4" className={tabIndex === 3 ? `active` : ''}>
              <ScheduleSvg /><span>Schema</span>
            </a>
            <a href="?tab=2" className={tabIndex === 2 ? `active` : ''}>
              <ScreenSvg /><span>Skärmar</span>
            </a>
            {isScenkonst && <a href="?tab=5" className={tabIndex === 5 ? `active` : ''}>
              <ScreenSvg /><span>Bildspel</span>
            </a>}
            <a href="#" onClick={logout}>
              <SettingsSvg /><span>Logga ut</span>
            </a>
          </nav>
          {tabIndex === 1 && <MenuEditor token={token} />}
          {tabIndex === 2 && <LayoutEditor token={token} />}
          {tabIndex === 3 && <ScheduleEditorText />}
          {tabIndex === 4 && <ScheduleEditor />}
          {tabIndex === 5 && <SlideshowEditor />}
          {modalContent}
        </>
      }
    </ThemeProvider>
  );
}

export default App