import styled from 'styled-components'

const Button = styled.button`
    background: none;
    font-family: inherit;
    font-size: inherit;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    font-variation-settings: 'wght' 455;
    transition: 0.2s;
    display: grid;
    align-items: center;
    padding: 0px;
    margin: 8px 4px 8px 4px;
    padding: 8px 16px 8px 16px;
    min-width: 150px;

    &:focus {
        outline: 0;
    }

    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    transition: 0.2s;

    &:hover, &:focus {
        transform: scalex(1.02);
    }
`

export const PrimaryButton = styled(Button)`
    color: #fff;
    background-color: ${props => props.theme.primaryColor};
    height: 48px;
`

export const SmallButton = styled(Button)`
    text-transform: uppercase;
    color: #fff;
    background-color: ${props => props.theme.primaryColor};
    letter-spacing: 2px;
    min-width: 80px;
    padding: 8px 24px 8px 24px;
    font-weight: bold;
    margin: 8px 4px;
`

export const InlineSmallButton = styled(SmallButton)`
    display: inline;
`

export const SecondaryButton = styled(Button)`
    color: #fff;
    background-color: ${props => props.theme.secondaryColor};
    height: 48px;
`
