import create from 'zustand'
import produce from 'immer'

export const useStore = create((set, get) => ({
  modalContent: null,
  showModal: (content) => set(produce(state => {
    state.modalContent = content
  })),
  closeModal: () => set(produce(state => {
    state.modalContent = null
  })),
  unsaved: false,
  setUnsaved: (value) => set(produce(state => {
    state.unsaved = value
  }))
}))

export default { useStore }
