import { useRef, useEffect } from 'react'
import TextValueEdit from './TextValueEdit'
import { getPropertyStatus } from '../utils'

const GenericProperty = props => {
  const { name, baseValue, newValue, onSave } = props
  const status = getPropertyStatus(props)
  const inputEl = useRef(null)

  // Vid tilläggning av ny rätt blir automagiskt fältet för namnet input fält /b2
  useEffect(() => {
    if (name === 'title' && !baseValue && !newValue) {
      inputEl.current.focus()
    }
  })

  return (
    <div
      className={`property property-status--${status} property-name--${name}`}
      onClick={() => {
        inputEl.current.focus()
      }}
      name={name}
    >
      <span className={'text--name'}>{name}</span>
      <span className={'text--value text--base-value'}>{baseValue}</span>
      <TextValueEdit
        className={'value-text-edit'}
        previousValue={newValue ?? baseValue}
        onSave={onSave}
        newValue={newValue}
        inputRef={inputEl}
        placeholder={name}
      />
    </div>
  )
}

export default GenericProperty
