import React, { useState, useRef, useEffect, useCallback } from 'react'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

const fetchDevices = () => {
  return new Promise(resolve => {
    axios.get('restaurants/<restaurant_id>/devices')
      .then(response => {
        const data = response.data
        data.sort((a, b) => a.device_id > b.device_id ? 1 : -1)
        resolve(data)
      })
  })
}

const fetchLayouts = () => {
  return new Promise(resolve => {
    axios.get('restaurants/<restaurant_id>/layouts')
      .then(response => {
        const data = response.data
        data.sort((a, b) => a.layout_id > b.layout_id ? 1 : -1)
        resolve(data)
      })
  })
}

const fetchSchedule = (deviceId) => {
  return new Promise(resolve => {
    axios.get(`devices/${deviceId}/schedule`)
      .then(response => {
        resolve(response.data)
      })
  })
}

const CalendarItem = ({ index, rowstart, colstart, rowend, colend, label, onResize, onMove, onDelete, onSelect, initialResizing, layouts, isSelected, uuid, onChangeSelectedLayoutId, selectedLayoutId }) => {
  const [resizing, setResizing] = useState(initialResizing)
  const [moving, setMoving] = useState()
  const moveStartRef = useRef()

  const onStartResize = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const edits = e.target.getAttribute('edits')
    // console.log("start edit", edits)
    setResizing(edits)

    onSelect(index)
  }

  const onStartMove = (e) => {
    const isMoveElement = e.target.classList.contains("calendar-grid-item")
    if (isMoveElement) {
      e.preventDefault()
      // console.log("onStartMove", e.target.className)
      setMoving("init")
    }

    onSelect(index)
  }

  useEffect(() => {
    if (!resizing) {
      return
    }


    const handleMouseMove = (e) => {
      var hoverTarget = document.elementFromPoint(e.x, e.y)
      if (!hoverTarget) {
        return
      }

      for (const prop of resizing.split(" ")) {
        const newValue = parseInt(hoverTarget.getAttribute(prop))
        // const newValue = parseInt(e.target.getAttribute(prop))
        if (newValue) {
          onResize(index, prop, newValue)
        }
      }
    }

    window.addEventListener('pointermove', handleMouseMove)
    window.addEventListener('pointerup', (e) => {
      window.removeEventListener('pointermove', handleMouseMove)
      setResizing(null)
    }, { once: true })
  }, [resizing])

  useEffect(() => {
    if (!moving) {
      return
    }

    const handleMouseMove = (e) => {
      var hoverTarget = document.elementFromPoint(e.x, e.y)
      if (!hoverTarget) {
        return
      }

      const hit = hoverTarget.classList.contains("calendar-grid-bg")
      // const hit = e.target.className.includes("calendar-grid-bg")
      if (!hit) {
        return
      }

      const rect = {
        rowstart: parseInt(hoverTarget.getAttribute("rowstart")),
        rowend: parseInt(hoverTarget.getAttribute("rowend")),
        colstart: parseInt(hoverTarget.getAttribute("colstart")),
        colend: parseInt(hoverTarget.getAttribute("colend"))
        // rowstart: parseInt(e.target.getAttribute("rowstart")),
        // rowend: parseInt(e.target.getAttribute("rowend")),
        // colstart: parseInt(e.target.getAttribute("colstart")),
        // colend: parseInt(e.target.getAttribute("colend"))
      }

      if (!moveStartRef.current) {
        moveStartRef.current = {
          rowstart: rowstart - rect.rowstart,
          colstart: colstart - rect.colstart,
        }
        // console.log("moveStartRef initialized to", moveStartRef.current)
      }

      const height = rowend - rowstart
      const width = colend - colstart

      const newRowStart = Math.min(49 - height,
        Math.max(1, rect.rowstart + moveStartRef.current.rowstart))
      const newColStart = Math.min(8 - width,
        Math.max(1, rect.colstart + moveStartRef.current.colstart))

      const newPos = {
        rowstart: newRowStart,
        rowend: newRowStart + height,
        colstart: newColStart,
        colend: newColStart + width,
      }

      onMove(index, newPos)
    }

    // console.log("subscribing")
    window.addEventListener('pointermove', handleMouseMove)
    window.addEventListener('pointerup', (e) => {
      moveStartRef.current = null
      window.removeEventListener('pointermove', handleMouseMove)
      setMoving(null)
    }, { once: true })

  }, [moving])

  // console.log("rowstart internal", rowstart)
  const gridArea = `${rowstart}/${colstart}/${rowend}/${colend}`
  const style = {
    gridArea: gridArea,
    pointerEvents: (resizing || moving) ? "none" : "auto"
  }

  // if (Math.abs(colstart - colend) <= 1) {
  label = label.split(" ")[1]
  // }

  const [topText, bottomText] = label.split("-")

  return <div className={`calendar-grid-item ${isSelected ? 'selected' : ''}`} style={style} onPointerDown={onStartMove}>
    {/* <div className="drag-top" edits={"rowstart"} onPointerDown={onStartResize}></div> */}
    <div className="drag-bottom" edits={"rowend"} onPointerDown={onStartResize}></div>
    {/* <div className="drag-left" edits={"colstart"} onPointerDown={onStartResize}></div> */}
    <div className="drag-right" edits={"colend"} onPointerDown={onStartResize}></div>
    <div className="drag-bottom-right" edits={"rowend colend"} onPointerDown={onStartResize}></div>
    <div className="drag-bottom-right-visual"></div>
    <div className="calendar-grid-item-label-top">{topText}</div>
    <div className="calendar-grid-item-label-bottom">{bottomText}</div>
    <button className="calendar-grid-item-remove" onClick={() => { console.log(resizing); if (!resizing) { onDelete(uuid) } }}>🗑</button>

    <select className="calendar-item-layout-select" onChange={e => onChangeSelectedLayoutId(uuid, e.target.value)} defaultValue={selectedLayoutId}>
      {layouts.map(({ name, layout_id }) =>
        <option key={layout_id} value={layout_id}>{name}</option>
      )}
    </select>

  </div>
}

const DeviceScheduleEditor = ({ deviceId, layouts, onScheduleChanged }) => {
  const [items, setItems] = useState([])
  const [defaultLayoutId, setDefaultLayoutId] = useState(null)

  useEffect(() => {
    var result = items.map(item => ({ 
      opening_hours: getOpeningHoursString(item),
      layout_id: item.layout_id,
      priority: 1
    }))
    result.push({ layout_id: defaultLayoutId, opening_hours: "24/7", priority: 0 })

    onScheduleChanged(deviceId, result)
  }, [items, defaultLayoutId])
  
  /*[{
  rowstart: 5,
  colstart: 2,
  rowend: 11,
  colend: 3
}, {
  rowstart: 10,
  colstart: 5,
  rowend: 13,
  colend: 7
}]*/

  const [selectedItemIndex, setSelectedItemIndex] = useState(-1)

  const weekdays = [
    "Mo",
    "Tu",
    "We",
    "Th",
    "Fr",
    "Sa",
    "Su"
  ]

  useEffect(() => {
    fetchSchedule(deviceId)
      .then(schedule => {
        // console.log(schedule)
        schedule.sort((a, b) => a.priority > b.priority ? 1 : -1)

        const newItems = []

        var newDefaultLayoutId = layouts[0].layout_id

        for (const { opening_hours, layout_id, priority } of schedule) {
          const isDefaultItem = opening_hours === "24/7"
          if (isDefaultItem) {
            newDefaultLayoutId = layout_id
            continue
          }

          const re = /(\w\w)-?(\w\w)? (\d\d):(\d\d)-(\d\d):(\d\d)/
          const match = opening_hours.match(re)
          if (match) {
            // console.log(match[1], match[2])
            const colstart = weekdays.indexOf(match[1]) + 1
            const colend = match[2] ? weekdays.indexOf(match[2]) + 2 : colstart + 1
            const rowstart = parseInt(match[3]) * 2 + parseInt(match[4] / 30) + 1
            const rowend = parseInt(match[5]) * 2 + parseInt(match[6] / 30) + 1

            const newItem = {
              colstart,
              colend,
              rowstart,
              rowend,
              layout_id,
              priority,
              uuid: uuidv4(),
            }

            // console.log(newItem)
            newItems.push(newItem)
          }
        }

        setDefaultLayoutId(newDefaultLayoutId)

        setItems(newItems)
      })
  }, [])

  const getOpeningHoursString = (item) => {
    let dayString
    const isMultipleDays = item.colend > item.colstart + 1
    if (isMultipleDays) {
      dayString = `${weekdays[item.colstart - 1]}-${weekdays[item.colend - 2]}`
    } else {
      dayString = weekdays[item.colstart - 1]
    }

    const startTime = (item.rowstart - 1) / 2
    const startHour = String(Math.floor(startTime)).padStart(2, '0')
    const startMinute = String((startTime % 1) * 60).padStart(2, '0')
    const endTime = (item.rowend - 1) / 2
    const endHour = String(Math.floor(endTime)).padStart(2, '0')
    const endMinute = String((endTime % 1) * 60).padStart(2, '0')
    const timeString = `${startHour}:${startMinute}-${endHour}:${endMinute}`

    const openingHoursString = `${dayString} ${timeString}`
    return openingHoursString
  }

  const addNewItem = (rowstart, colstart) => {
    setItems(items => {
      const newItem = {
        rowstart,
        colstart,
        rowend: rowstart + 2,
        colend: colstart + 1,
        initialResizing: "colend rowend",
        uuid: uuidv4(),
        layout_id: layouts.length > 0 ? layouts[0].layout_id : null
      }
      return [...items, newItem]
    })
    setSelectedItemIndex(items.length)
  }

  const bgItems = []
  for (let day = 1; day <= 7; day++) {
    for (let hour = 1; hour <= 48; hour++) {
      const bgItem = {
        rowstart: hour,
        colstart: day,
        rowend: hour + 1,
        colend: day + 1
      }

      const gridArea = `${bgItem.rowstart}/${bgItem.colstart}/${bgItem.rowend}/${bgItem.colend}`

      const style = {
        gridArea: gridArea
      }

      bgItems.push(<div
        style={style}
        key={gridArea}
        className="calendar-grid-bg"
        onPointerDown={(e) => {
          addNewItem(bgItem.rowstart, bgItem.colstart)
        }}
        {...bgItem}
      ></div>)
    }
  }

  const onResize = (index, prop, value) => {
    // console.log("onResize", index, prop, value)
    setItems(items => {
      let item = items[index]
      const previousValue = item[prop]
      const newValue = value
      if (previousValue === newValue) {
        return items
      }

      let valid = true

      // create new item. only used if validation passes. 
      const newItem = { ...item }
      newItem[prop] = value

      if (prop === "colend" && item["colstart"] > value - 1) {
        valid = false
      } else if (prop === "rowend" && item["rowstart"] > value - 2) {
        valid = false
      } else if (prop === "colstart" && item["colend"] < value + 1) {
        valid = false
      } else if (prop === "rowstart" && item["rowend"] < value + 2) {
        valid = false
      }

      // collision check
      for (var i = 0; i < items.length; i++) {
        if (i === index) {
          continue
        }

        const otherItem = items[i]

        // loop through all blocks of newItem
        for (var x = newItem.colstart; x < newItem.colend; x++) {
          for (var y = newItem.rowstart; y < newItem.rowend; y++) {

            // is x,y within other item rect
            if (x >= otherItem.colstart && x < otherItem.colend && y >= otherItem.rowstart && y < otherItem.rowend) {
              valid = false
              break
            }
          }
        }
      }

      if (valid) {
        items[index] = newItem
        return [...items]
      }

      return items
    })
  }

  const onMove = (index, rect) => {
    setItems(items => {
      
      var valid = true

      // collision check
      for (var i = 0; i < items.length; i++) {
        if (i === index) {
          continue
        }

        const otherItem = items[i]

        // loop through all blocks of rect
        for (var x = rect.colstart; x < rect.colend; x++) {
          for (var y = rect.rowstart; y < rect.rowend; y++) {

            // is x,y within other item rect
            if (x >= otherItem.colstart && x < otherItem.colend && y >= otherItem.rowstart && y < otherItem.rowend) {
              valid = false
              break
            }
          }
        }
      }

      if (valid) {
        items[index].rowstart = rect.rowstart
        items[index].rowend = rect.rowend
        items[index].colstart = rect.colstart
        items[index].colend = rect.colend
      }

      return [...items]
    })
  }

  const onSelect = (index) => {
    setSelectedItemIndex(index)
  }

  const onDelete = (uuid) => {
    setSelectedItemIndex(-1)

    setItems(items => {
      return items.filter(x => x.uuid !== uuid)
    })
  }

  // gurgel
  const onChangeSelectedLayoutId = useCallback((item_uuid, layout_id) => {
    // console.log("onChangeSelectedLayoutId", item_uuid, layout_id)
    const changedItemIndex = items.findIndex(x => x.uuid === item_uuid)
    items[changedItemIndex].layout_id = layout_id

    setItems(items => {
      return [...items]
    })
  }, [items])

  const onChangeDefaultLayoutId = (deviceId, newValue) => {
    setDefaultLayoutId(newValue)
  }

  if (defaultLayoutId === null) {
    return "Loading..."
  }

  return <>
    <select onChange={e => onChangeDefaultLayoutId(deviceId, e.target.value)} defaultValue={defaultLayoutId}>
      {layouts.map(({ name, layout_id }) =>
        <option key={layout_id} value={layout_id}>{name}</option>
      )}
    </select>

    <div className="schedule-editor-calendar">
      {bgItems}
      {items.map((item, index) => {
        // console.log(index)
        const ohstring = getOpeningHoursString(item)
        return <CalendarItem
          key={item.uuid}
          index={index}
          {...item}
          onResize={onResize}
          onMove={onMove}
          label={ohstring}
          layouts={layouts}
          onSelect={onSelect}
          isSelected={selectedItemIndex === index}
          onDelete={onDelete}
          onChangeSelectedLayoutId={onChangeSelectedLayoutId}
          selectedLayoutId={item.layout_id}
        />
      }
      )}
    </div>
  </>
}

const ScheduleEditor = () => {
  const [devices, setDevices] = useState()
  const [currentDeviceId, setCurrentDeviceId] = useState()
  const [layouts, setLayouts] = useState()

  // const currentDevice = devices && devices.find(x => x.device_id === currentDeviceId)

  useEffect(() => {
    if (!devices) {
      fetchDevices()
        .then(data => {
          setDevices(data)
          // setDevices([data[0]])
          if (data.length > 0) {
            setCurrentDeviceId(data[0].device_id)
          }
        })
    }
    if (!layouts) {
      fetchLayouts()
        .then(setLayouts)
    }
  }, [])

  const save = (deviceId, data) => {
    if (!data || data.length === 0) {
      return
    }

    console.log("saving", data)

    axios
      .put(`devices/${deviceId}/schedule`, data)
      .then(response => {
        if (response.status !== 200) {
          console.error(response)
        }
      })
  }

  const saveDebounceTimerRef = useRef(null)

  const onScheduleChanged = (deviceId, result) => {
    // console.log("hehu")

    if (saveDebounceTimerRef.current) {
      clearTimeout(saveDebounceTimerRef.current)
    }
    saveDebounceTimerRef.current = setTimeout(() => { save(deviceId, result) }, 500)
  }



  if (!layouts || !devices) {
    return "Laddar..."
  }

  return <div className="schedule-editor">
    {devices && devices.map(device =>
      <div className="schedule-editor-device" key={device.device_id}>
        <b>{device.name}</b>
        <div className="schedule-editor-calendar-weekday-wrapper">
          <div className="schedule-editor-weekday-titles">
            <div>Måndag</div>
            <div>Tisdag</div>
            <div>Onsdag</div>
            <div>Torsdag</div>
            <div>Fredag</div>
            <div>Lördag</div>
            <div>Söndag</div>
          </div>
          <DeviceScheduleEditor
            deviceId={device.device_id}
            device={device}
            layouts={layouts}
            onScheduleChanged={onScheduleChanged}
          />
        </div>
      </div>
    )}
  </div>
}

export default ScheduleEditor
