import styled from 'styled-components'
import { PrimaryButton } from './Button'

const FormInput = styled.input`
    font-family: inherit;
    font-size: inherit;
    border-radius: 6px;
    border: none;
    box-sizing: border-box;
    text-align: center;
    height: 48px;
    width: 280px;
    margin: 8px;
    transition: 0.2s;
`

export const EmailInput = styled(FormInput).attrs({ name: 'email' })``

export const PasswordInput = styled(FormInput).attrs({ type: 'password', name: 'password' })``

export const FormButton = styled(PrimaryButton)`
    width: 280px;

    &::before {
        width: 300px;
    }
`
